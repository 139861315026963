import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'snaporiginals.snapchat.com',

  globalNavProps: {
    defaultGroupKey: 'snapchat',
  },
  trackingSettings: {
    cookieDomain: 'snapchat.com',
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.ORIGINALS },
      {
        vendor: LoggingVendor.GOOGLE,
        googleTagManagerId: 'GTM-PB72WQ3',
        googleAnalyticsId: 'G-772W08WT87',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'snaporiginals-snapchat-com',
        dsn: 'https://0456b0a826e441f08382f9879eeb5f71@sentry.sc-prod.net/82',
      },
    ],
  },
};
